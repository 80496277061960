import { createSelector } from '@reduxjs/toolkit';

import {
  AvatarDisplayOptions,
  IChatMedia,
  IForwardChatOption,
  IForwardMessageOption,
  IMessageCount,
  IMessageItem,
  IMessageItemDetails,
  IMessagesStore,
  ISearchChatOption,
  ISendMessageChatOption,
  ISidebarUser,
  IStore2,
  MessagesTypes,
  popupIdsOptions,
} from 'types';
import { UserSelector } from 'store/user/UserSelector';
import { parseSidebarMessages } from 'pages/chatv2/sideBar/helper';
import { translations } from 'translations/en';
import { getMessageImagesAndNextIndex, getUpdatedGroupCount, parseMessage } from './helper';
import { isEmptyArray, isSameDay } from 'utils';
import { toMembersFormat } from 'pages/chatv2/feed/helper';
import { getFirstLink } from 'pages/chatv2/messages/footer/input/helper';

const DEFAULT_GROUP_ITEM = {
  id: '',
  name: '',
  avatar: '',
  description: '',
  participants: 0,
};

const DEFAULT_MEDIA_ITEM = {
  source: '',
  userId: '',
  id: '',
  date: 0,
  isOwner: false,
  username: '',
  avatar: '',
  text: '',
};

const selectedGroupSelector = (state: IStore2) => state.popup.selectedGroup;

const popupIdsSelector = (state: IStore2) => state.popup.ids;

const groupMemberSelector = (state: IStore2) => state.chat.selectedChat?.members?.users || [];

const groupPrivacySelector = (state: IStore2) => state.chat.selectedChat?.privacy || null;

const searchTextSelector = (state: IStore2) => state.chat.searchText;

const isFilterActiveSelector = (state: IStore2) => state.chat.isFilterActive;

const searchBatchIndexSelector = (state: IStore2) => {
  if (!state.chat.selectedChat) {
    return null;
  }

  return state.chat.selectedChat.searchMessages.currentMessageBatchIndex;
};

const messagesGroupCountSelector = (state: IStore2) =>
  state.chat.selectedChat?.messagesGroupCount || [];

const selectedMessagesIdsSelector = (state: IStore2) =>
  state.chat.selectedChat?.selectedMessages || [];

const selectedMessageDateSelector = (state: IStore2) =>
  state.chat.selectedChat?.selectedMessageDate;

const messagesSelector = (state: IStore2) => state.chat.selectedChat?.messages || [];

const shopsSelector = (state: IStore2) => state.chat.shops;

const groupsSelector = (state: IStore2) => state.chat.groups;

const chatSearchMessagesSelector = (state: IStore2) =>
  state.chat.selectedChat?.searchMessages || {
    isSearchVisible: false,
    search: '',
    isLoading: false,
    hasMore: true,
    skip: 0,
    totalCount: 0,
    messagesBatches: [],
    currentMessageBatchIndex: 0,
  };

const defaultMediaItemSelector = (state: IStore2) => state.popup.mediaItem;

const suggestedGroupMembersSearchSelector = (state: IStore2) =>
  state.chat.createChatDetails.suggestedMembers.search;

const searchMessagesSelector = (state: IStore2) => state.chat.searchResults.searchedMessages;

const archivedChatsSelector = (state: IStore2) => state.chat.archivedChats;

const selectedGroupMemberSelector = (state: IStore2) =>
  state.chat.createChatDetails.suggestedMembers.selectedUsers;

const suggestedGroupMembersSelector = (state: IStore2) =>
  state.chat.createChatDetails.suggestedMembers.users;

const searchMessagesTotalCountSelector = (state: IStore2) =>
  state.chat.searchResults.totalSearchedMessages;

const chatsSelector = (state: IStore2) => state.chat.chats;

const selectedChatIdSelector = (state: IStore2) => state.chat.selectedChat?.id || null;

const selectedChatSelector = (state: IStore2) => state.chat.selectedChat;

const selectionModeSelector = (state: IStore2) => state.chat.selectedChat?.selectionMode || null;

const mediaGallerySelector = (state: IStore2) => state.chat.selectedChat?.mediaGallery;

const totalSelectedMessagesSelector = (state: IStore2) =>
  state.chat.selectedChat?.selectedMessages?.length || 0;

const getSearchResultsSelector = createSelector(
  [searchTextSelector, chatsSelector],
  (searchText, chats): { groups: ISearchChatOption[]; users: ISearchChatOption[] } => {
    const groups =
      chats?.filter(
        (chat) => chat.isGroup && chat.name.toLowerCase().includes(searchText.toLowerCase()),
      ) || [];

    const parsedGroups: ISearchChatOption[] = groups.map((group) => ({
      id: group.id,
      isGroup: group.isGroup,
      avatar: group.avatar,
      name: group.name,
      additionalInfo: `${group.totalParticipants} Participants`,
      isSuperAdmin: false,
    }));

    const users =
      chats?.filter(
        (chat) =>
          (!chat.isGroup && chat.name.toLowerCase().includes(searchText.toLowerCase())) ||
          chat.username?.toLowerCase().includes(searchText.toLowerCase()),
      ) || [];

    const parsedUsers: ISearchChatOption[] = users.map((user) => ({
      id: user.id,
      isGroup: user.isGroup,
      isSuperAdmin: user.isSuperAdmin,
      avatar: user.avatar || '',
      name: user.name,
      additionalInfo: user.username || '',
    }));

    return { groups: parsedGroups, users: parsedUsers };
  },
);

const hasMoreSearchMessagesSelector = createSelector(
  [searchMessagesTotalCountSelector, searchMessagesSelector],
  (totalCount, messages) => {
    if (messages) {
      return messages.length < totalCount;
    }

    return true;
  },
);

const checkIfLinksPopupVisible = createSelector([popupIdsSelector], (popupIds): boolean => {
  return !!popupIds.find((id) => id === popupIdsOptions.linksNotAllowedPopup);
});

const isOnlineSelector = createSelector([selectedChatSelector], (selectedChat) => {
  if (!selectedChat) {
    return false;
  }

  return selectedChat.lastSeen === 'online';
});

const currentMessageIndexSelector = createSelector(
  [chatSearchMessagesSelector],
  (searchMessages) => {
    if (!searchMessages.isSearchVisible) {
      return 0;
    }

    const batches = searchMessages.messagesBatches.slice(
      0,
      searchMessages.currentMessageBatchIndex + 1,
    );

    return batches.reduce((acc, item) => acc + item.currentIndex + 1, 0) - 1;
  },
);

const selectedMessagesSelector = createSelector(
  [selectedMessagesIdsSelector, messagesSelector],
  (selectedMessagesIds, messages): IForwardMessageOption[] => {
    const ids = selectedMessagesIds.map((id) => id.split('|')).flat();

    // @ts-ignore
    const filteredMessages: IMessagesStore[] = ids
      .map((id) => messages.find((message) => message.id === id))
      .filter((message) => message !== undefined);

    return filteredMessages.map((message) => ({
      id: message.id,
      file: message.fileDetails,
      orderDetails: message.orderDetails,
      postDetails: message.postDetails,
      image: message.images[0] || null,
      replyDetails: message.reply,
      text: message.text,
      isForwarded: message.isForwarded,
      isOwner: message.isOwner,
      type: message.type,
    }));
  },
);

const forwardMessagesSelector = createSelector(
  [chatsSelector, selectedChatIdSelector, selectedMessagesIdsSelector, messagesSelector],
  (chats, selectedChatId, selectedMessagesIds, messages): IForwardChatOption[] => {
    if (!chats) {
      return [];
    }

    const isSelectedMessagesContainsLink = messages.find(
      (message) =>
        !!selectedMessagesIds.find((id) => message.id === id) && getFirstLink(message.text),
    );

    return chats
      .filter(
        (chat) => chat.id !== selectedChatId && (!isSelectedMessagesContainsLink || !chat.isGroup),
      )
      .map((chat) => ({
        name: chat.name,
        avatar: chat.avatar,
        isGroup: chat.isGroup,
        id: chat.id,
        username: chat.isGroup ? null : chat.username,
      }));
  },
);

const messagesInitialIndexSelector = createSelector(
  [selectedMessageDateSelector, messagesSelector],
  (selectedMessageDate, messages) => {
    const index = messages.findIndex((message) => message.date === selectedMessageDate);

    if (index === -1) {
      if (messages.length === 0) {
        return 0;
      }

      return messages.length - 1;
    }

    return index;
  },
);

const chatDescriptionSelector = createSelector([selectedChatSelector], (selectedChat) => {
  if (!selectedChat) {
    return '';
  }

  const { typingName, isGroup, totalParticipants, lastSeen, channel } = selectedChat;

  if (!typingName) {
    if (isGroup || channel) {
      if (totalParticipants === 1) return '';

      return toMembersFormat(totalParticipants);
    }

    return lastSeen;
  }
  if (isGroup) {
    return `${typingName} is ${translations.CHAT.TYPING.toLowerCase()}`;
  }

  return `${translations.CHAT.TYPING}`;
});

const totalSelectedGroupMembersSelector = createSelector(
  [selectedGroupMemberSelector],
  (selectedMembers) => {
    return selectedMembers.length;
  },
);

const suggestedMembersSelector = createSelector(
  [suggestedGroupMembersSelector, selectedGroupMemberSelector, suggestedGroupMembersSearchSelector],
  (suggestedGroupMembers, selectedGroupMembers, search): ISidebarUser[] => {
    if (!search) {
      let selectedMembersToAdd = selectedGroupMembers;

      const suggestions = suggestedGroupMembers.map((suggestion) => {
        const isSelected =
          selectedMembersToAdd.find((item) => item.id === suggestion.id) !== undefined;

        if (isSelected) {
          selectedMembersToAdd = selectedMembersToAdd.filter((item) => item.id !== suggestion.id);
        }

        return {
          id: suggestion.id,
          avatar: suggestion.avatar,
          username: suggestion.username,
          name: suggestion.name,
          isSelected,
        };
      });

      const selectedUsers = selectedMembersToAdd.map((selectedMember) => ({
        id: selectedMember.id,
        avatar: selectedMember.avatar,
        username: selectedMember.username,
        name: selectedMember.name,
        isSelected: true,
      }));

      return [...selectedUsers, ...suggestions];
    }

    return suggestedGroupMembers.map((suggestedMember) => {
      const isSelected = !!selectedGroupMembers.find(
        (selectedMember) => selectedMember.id === suggestedMember.id,
      );

      return {
        id: suggestedMember.id,
        avatar: suggestedMember.avatar,
        name: suggestedMember.name,
        username: suggestedMember.username,
        isSelected,
      };
    });
  },
);

const getChatsSelector = createSelector(
  [
    chatsSelector,
    selectedChatIdSelector,
    UserSelector.loggedUserNameSelector,
    isFilterActiveSelector,
  ],
  (chats, selectedChatId, username, isFilterActive): IMessageItem[] | null => {
    if (!chats || !username) {
      return null;
    }

    return parseSidebarMessages(chats, selectedChatId, username, isFilterActive);
  },
);

const getArchivedChatsSelector = createSelector(
  [archivedChatsSelector, selectedChatIdSelector, UserSelector.loggedUserNameSelector],
  (chats, selectedChatId, username): IMessageItem[] => {
    if (!username) {
      return [];
    }

    return parseSidebarMessages(chats, selectedChatId, username);
  },
);

const currentGroupItem = createSelector([selectedGroupSelector], (selectedGroup) => {
  return selectedGroup || DEFAULT_GROUP_ITEM;
});

const chatInformationDescriptionSelector = createSelector(
  [selectedChatSelector, groupPrivacySelector],
  (selectedChat) => {
    if (!selectedChat) {
      return '';
    }
    if (selectedChat.isGroup) {
      return toMembersFormat(selectedChat.totalParticipants);
    }

    return `@${selectedChat.username}`;
  },
);

const chatMessagesSelector = createSelector(
  [
    messagesSelector,
    shopsSelector,
    groupsSelector,
    selectedMessagesIdsSelector,
    messagesGroupCountSelector,
    chatSearchMessagesSelector,
  ],
  (
    messages,
    shops,
    groups,
    selectedMessages,
    messagesGroupCount,
    chatSearchMessages,
  ): {
    messages: IMessageItemDetails[];
    groupsCount: IMessageCount[];
    searchIndex: number | null;
  } => {
    let updatedGroupCount = messagesGroupCount;

    if (isEmptyArray(messages)) {
      return { messages: [], groupsCount: [], searchIndex: null };
    }

    let i = 0;

    const { currentMessageBatchIndex, messagesBatches } = chatSearchMessages;

    const currentBatch = messagesBatches[currentMessageBatchIndex];

    let searchIndex = currentBatch?.searchMessageIndexes[currentBatch?.currentIndex || 0];

    const parsedMessages: IMessageItemDetails[] = [];

    while (i < messages.length) {
      const currentMessage = { ...messages[i] };

      const { date, username } = currentMessage;

      const { nextIndex, images, id } = getMessageImagesAndNextIndex(messages, i);

      if (images.length > 1) {
        updatedGroupCount = getUpdatedGroupCount(updatedGroupCount, date, images.length - 1);
        currentMessage.images = images;
        currentMessage.id = id;
        currentMessage.type = MessagesTypes.Gallery;
        if (i < searchIndex) {
          searchIndex -= images.length - 1;
        }
      }

      i = nextIndex;

      const prevMessage = parsedMessages[parsedMessages.length - 1];

      const isDifferentUserOrDay =
        !prevMessage ||
        prevMessage.username !== username ||
        !isSameDay(prevMessage.date, date) ||
        prevMessage.type === MessagesTypes.System;

      const shouldDisplayHeader = currentMessage.shouldDisplayName && isDifferentUserOrDay;

      if (
        !isDifferentUserOrDay &&
        prevMessage.avatarDisplayOption === AvatarDisplayOptions.Visible
      ) {
        prevMessage.avatarDisplayOption = AvatarDisplayOptions.Invisible;
      }

      const parsedMessage = parseMessage(
        currentMessage,
        shops,
        groups,
        selectedMessages,
        shouldDisplayHeader,
        !isDifferentUserOrDay,
      );
      parsedMessages.push(parsedMessage);
    }

    return {
      messages: parsedMessages,
      groupsCount: updatedGroupCount,
      searchIndex: chatSearchMessages.isSearchVisible ? searchIndex : null,
    };
  },
);

const selectedImageSelector = createSelector(
  [mediaGallerySelector, defaultMediaItemSelector],
  (mediaGallery, defaultMediaItem): IChatMedia => {
    return (
      mediaGallery?.media[mediaGallery?.currentIndex] || defaultMediaItem || DEFAULT_MEDIA_ITEM
    );
  },
);

const sendMessageChatsSelector = createSelector(
  [chatsSelector],
  (chats): ISendMessageChatOption[] => {
    return (
      chats?.map((chat) => ({
        ...chat,
        isSent: false,
      })) || []
    );
  },
);

const totalUnreadMessagesSelector = createSelector([chatsSelector], (chats = []): number => {
  return chats?.reduce((acc, item) => acc + item.totalUnread, 0) || 0;
});

export const ChatSelector = {
  getSearchResultsSelector,
  totalSelectedGroupMembersSelector,
  searchTextSelector,
  getArchivedChatsSelector,
  getChatsSelector,
  hasMoreSearchMessagesSelector,
  chatDescriptionSelector,
  totalSelectedMessagesSelector,
  selectedChatIdSelector,
  selectedMessagesIdsSelector,
  selectedChatSelector,
  chatMessagesSelector,
  selectedMessagesSelector,
  selectionModeSelector,
  messagesInitialIndexSelector,
  suggestedMembersSelector,
  forwardMessagesSelector,
  searchMessagesSelector,
  selectedImageSelector,
  isOnlineSelector,
  chatSearchMessagesSelector,
  searchBatchIndexSelector,
  currentGroupItem,
  checkIfLinksPopupVisible,
  currentMessageIndexSelector,
  groupMemberSelector,
  chatInformationDescriptionSelector,
  sendMessageChatsSelector,
  totalUnreadMessagesSelector,
};
