import {
  AvatarDisplayOptions,
  IChatGroupState,
  IChatMedia,
  IChatShopState,
  IExpiry,
  IMessageBaseDetails,
  IMessageLinkDetails,
  IPrice,
  IPriceResponse,
  MessageActionOptions,
  MessageSelectionOptions,
  MessagesStatuses,
  MessagesTypes,
  OrderStatuses,
} from 'types';
import { CurrencyCode } from 'constant/currencies';

export interface IMessageHeader {
  name: string;
  color: string;
  username: string | null;
  isTranslated: boolean;
  isForwarded: boolean;
  shouldDisplayName: boolean;
  onNameClick(): void;
}

export interface IMessageLinkHeader {
  isPremium: boolean;
  avatar: string;
  name: string;
  username: string | null;
}

export interface IMessageBase {
  date: number;
  id: string;
  avatarDisplayOption: AvatarDisplayOptions;
  isDeletable: boolean;
  isExpired: boolean;
  isSequenceMessage: boolean;
  avatar: string | null;
  isOwner: boolean;
  isTranslated: boolean;
  shouldDisplayName: boolean;
  isSelected: boolean;
  expiryText: string | null;
  isForwarded: boolean;
  searchWords: string[];
  isMessagePrivatelyEnable: boolean;
  selectionMode: MessageSelectionOptions | null;
  name: string;
  username: string;
  userColor: string;
  status: MessagesStatuses | null;
  reply: IMessageReplyDetails | null;
  onNameClick(): void;
  onReplyClick(id: string, date: number): void;
  onSelectToggle(options: { id: string; isSelected: boolean }): void;
  onActionClick(messageId: string, action: MessageActionOptions): void;
  isChannel: boolean;
}

export interface IMessageText extends IMessageBase {
  text: string;
  isDeleted: boolean;
}

export interface IMessageImage extends IMessageBase {
  text: string | null;
  src: string;
  isVideo: boolean;
  onImageClick(mediaItem: IChatMedia): void;
}

export interface IMessageGallery extends IMessageBase {
  images: string[];
  onImageClick(mediaItem: IChatMedia): void;
}

export interface IMessageLink extends IMessageBase {
  link: IMessageLinkDetails;
  text: string;
}

export interface IMessageShop extends IMessageBase {
  shop: IChatShopState;
  text: string;
  onShopClick(id: string): void;
}

export interface IMessageShopDetails {
  id: string;
  isPremium: boolean;
  avatar: string;
  title: string;
  username: string;
  images: string[];
  description: string;
}

export interface IMessageGroupDetails {
  isMember: boolean;
  avatar: string | null;
  name: string;
  totalParticipants: number;
  isPublic: boolean;
  id: string;
  description: string;
  socialLinkId: string | null;
}

export interface IPostMessageDetails {
  isPremium: boolean;
  avatar: string;
  name: string;
  username: string;
  text: string | null;
  link: string;
  image: string | null;
  price: IPostPrice | null;
}

export interface IVisionMessageDetails {
  offerId: string;
  isDeclined: boolean;
  note: string;
  price: IPrice;
  deliveryTime: { min: number; max: number };
  returnPolicy: string;
  title: string;
  image: string;
  declineReason: string;
}

export interface IPostPrice {
  value: number;
  currencyCode: CurrencyCode;
}

export interface IMessagePostDetails {
  isPremium: boolean;
  avatar: string;
  name: string;
  username: string;
  text: string | null;
  image: string | null;
  searchWords: string[];
  price: IPostPrice | null;
}

export interface IMessageOrderDetails {
  status: OrderStatuses;
  title: string;
  description: string;
  orderName: string;
  images: string[];
  totalItemsCount: number;
  price: number;
  shippingPrice: number;
  orderId: string;
  senderName: string;
  shippingAddress: string;
  currencyCode: CurrencyCode;
  currencySymbol: string;
  declinedReason: string;
}

export interface IStoryUrlPostMessage {
  title?: string;
  description?: string;
  imageUrl?: string;
  post?: {
    isPremium: boolean;
    username: string;
    userId: string;
    name: string;
    avatar: string;
    price?: { price: number; currency: string; currencyCode: CurrencyCode };
  };
}

export interface IStoryUrlOrderMessage {
  offerId: string;
  title: string;
  description: string;
  shippingPrice?: number;
  itemPrice: {
    price: number;
    currency: string;
    currencyCode: CurrencyCode;
  };
  productImage: string;
  productImages?: string[];
  shippingAddress: string;
  status: OrderStatuses;
}

export interface IStoryUrlVisionMessage {
  offerId: string;
  sellerId: string;
  shippingPrice: number;
  price: IPriceResponse;
  productImage: string;
  deliveryTime: { min: number; max: number };
  returnPolicy: string;
  notes: string;
  status: 'OFFERED' | 'DECLINED';
  reason?: string;
}

export type SystemMessagesOptions =
  | 'userJoined'
  | 'userKicked'
  | 'userLeft'
  | 'avatarChanged'
  | 'nameChanged'
  | 'descChanged';

export interface IStoryUrlSystemMessage {
  type: SystemMessagesOptions;
  username?: string;
  by?: string;
  total: number;
}

export interface IMessageFileDetails {
  src: string;
  size: string;
  name: string;
  previewImage: string | null;
}

export interface IMessagePost extends IMessageBase {
  owner: {
    name: string;
    username: string;
    isPremium: boolean;
    avatar: string;
  };
  price: IPostPrice | null;
  postLink: string;
  image: string | null;
  text: string | null;
  onPostClick(id: string): void;
}

export interface IMessageReply {
  id: string;
  name: string;
  username: string;
  isReplyOwner: boolean;
  isMessageOwner: boolean;
  text: string | null;
  image: string | null;
  type: MessagesTypes;
  userColor: string;
  date: number;
  onClick(id: string, date: number): void;
}

export interface IMessageItemDetails extends IMessageBaseDetails {
  groupDetails: IChatGroupState;
  shopDetails: IChatShopState;
  isSequenceMessage: boolean;
  isSelected: boolean;
}

export interface IMessageWrapper extends IMessageItemDetails {
  selectionMode: MessageSelectionOptions | null;
  isMessagePrivatelyEnable: boolean;
  onFullNameClick(userId: string): void;
  onFileClick(file: string, isExpired: boolean, id: string, expiryText: string | null): void;
  onImageClick(mediaItem: IChatMedia): void;
  onShopView(id: string): void;
  onSelectToggle(options: { id: string; isSelected: boolean }): void;
  onPostView(id: string): void;
  onGroupView(groupId: string): void;
  onGroupJoin(groupId: string, isLink: boolean): void;
  onViewOrder(orderId: string): void;
  onViewVisionOffer(offerId: string, isOwner: boolean): void;
  onReplyClick(id: string, date: number): void;
  onActionClick(messageId: string, text: string, action: MessageActionOptions): void;
  isPremium: boolean;
  renderedImages: JSX.Element;
  isChannel: boolean;
}

export interface IMessageReplyDetails {
  name: string;
  username: string;
  id: string;
  isOwner: boolean;
  text: string | null;
  image: string | null;
  date: number;
  userColor: string;
  type: MessagesTypes;
}

export interface IMessageFile extends IMessageBase {
  file: string;
  fileSize: string;
  fileName: string;
  previewImage: string | null;
  text: string;
  onClick(file: string, isExpired: boolean, id: string, expiryText: string | null): void;
}

export interface PatternMessageWrapperProps extends IMessageFile {
  isPremium: boolean;
  expiry: IExpiry;
  renderedImages: JSX.Element;
}

export interface IMessagePatternBase {
  date: number;
  id: string;
  avatarDisplayOption: AvatarDisplayOptions;
  isDeletable: boolean;
  isSequenceMessage: boolean;
  avatar: string | null;
  isOwner: boolean;
  isTranslated: boolean;
  shouldDisplayName: boolean;
  isSelected: boolean;
  expiryText: string | null;
  isForwarded: boolean;
  searchWords: string[];
  isMessagePrivatelyEnable: boolean;
  selectionMode: MessageSelectionOptions | null;
  name: string;
  username: string;
  userColor: string;
  status: MessagesStatuses | null;
  reply: IMessageReplyDetails | null;
  onNameClick(): void;
  onReplyClick(id: string, date: number): void;
  onSelectToggle(options: { id: string; isSelected: boolean }): void;
  onActionClick(messageId: string, action: MessageActionOptions): void;

  file: string;
  fileSize: string;
  fileName: string;
  previewImage: string | null;
  text: string;
  onClick(file: string, isExpired: boolean, id: string, expiryText: string | null): void;
}

export interface IMessagePatternPro extends IMessagePatternBase {
  isPremium: boolean;
  expiry: IExpiry | null;
  showPopup(input: { id: string }): void;
  renderedImages: JSX.Element;
  isPatternImages;
  boolean;
}

export interface IMessagePatternRegular extends IMessagePatternBase {
  expiry: IExpiry | null;
  isExpired: boolean;
}

export interface IMessageGroup extends IMessageBase {
  group: IChatGroupState;
  onJoin(groupId: string, isLink: boolean): void;
  onView(groupId: string): void;
}

export interface IMessageOrderProps {
  orderId: string;
  status: OrderStatuses;
  title: string;
  orderName: string;
  images: string[];
  totalItemsCount: number;
  price: number;
  senderName: string;
  shippingAddress: string;
  currencyCode: CurrencyCode;
  declinedReason: string;
}

export interface IMessageOrder {
  date: number;
  id: string;
  avatar: string | null;
  isOwner: boolean;
  isSelected: boolean;
  avatarDisplayOptions: AvatarDisplayOptions;
  isSequenceMessage: boolean;
  isDeletable: boolean;
  selectionMode: MessageSelectionOptions | null;
  reply: IMessageReplyDetails | null;
  order: IMessageOrderProps;
  onViewOrder(orderId: string): void;
  onReplyClick(id: string, date: number): void;
  onSelectToggle(options: { id: string }): void;
  onActionClick(messageId: string, action: MessageActionOptions): void;
  isChannel: boolean;
}

export interface IMessageVision {
  date: number;
  id: string;
  avatar: string | null;
  isOwner: boolean;
  isSelected: boolean;
  avatarDisplayOptions: AvatarDisplayOptions;
  isSequenceMessage: boolean;
  isDeletable: boolean;
  selectionMode: MessageSelectionOptions | null;
  reply: IMessageReplyDetails | null;
  offer: IVisionMessageDetails;
  onViewOffer(orderId: string, isOwner: boolean): void;
  onReplyClick(id: string, date: number): void;
  onSelectToggle(options: { id: string }): void;
  onActionClick(messageId: string, action: MessageActionOptions): void;
  isChannel: boolean;
}

export interface IOrderDetailsWrapper {
  title: string;
  date: number;
  orderName: string;
  totalItems: number;
  price: number;
  status: OrderStatuses;
  currencyCode: CurrencyCode;
  shippingAddress: string;
  senderName: string;
  declineReason: string;
  images: string[];
  onViewOrder(): void;
}

export interface IVisionOfferDetailsWrapper {
  title: string;
  date: number;
  price: number;
  isDeclined: boolean;
  deliveryTime: { min: number; max: number };
  currencyCode: CurrencyCode;
  returnPolicy: string;
  note: string;
  declineReason: string;
  image: string;
  onViewOffer(): void;
}

export interface IForwardMessageOption {
  id: string;
  text: string;
  image: string | null;
  type: MessagesTypes;
  file: IMessageFileDetails | null;
  orderDetails: IMessageOrderDetails | null;
  postDetails: IPostMessageDetails | null;
  isForwarded: boolean;
  isOwner: boolean;
}

export enum FileAvailabilityStatus {
  REACHABLE,
  PRO_ONLY,
  EXPIRED_IN_WEEK,
  UNREACHABLE,
}
