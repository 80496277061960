import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { connect } from 'react-redux';

import { UserSelector } from 'store/user/UserSelector';
import { SOCKET_URL } from 'constant';
import { useSocket } from 'hooks/useSocket';
import {
  SendMessageRequest,
  IChatItem,
  SendMultipleMessages,
  IStore2,
  IMessageReceived,
  ISocketHandler,
} from 'types';
import { SocketContext } from './SocketContext';
import { ChatSlicer } from '../ChatSlicer';
import { formatMessageToSend, formatMultiMessagesToSend } from '../apiParser';
import { UserSlicer } from 'store/user/UserActions';
import { VisionsSlicer } from 'store/visions/VisionsSlicer';
import { SpecificVisionRequestSlicer } from 'store/specificVisionRequest/SpecificVisionRequestSlicer';
import { VisionOffer } from 'graphql/generated/graphql';
import { throttle, debounce } from 'utils';

const TYPING_DELAY = 2000;

const TYPING_REMOVE_DELAY = 2100;

const SocketWrapper: FC<ISocketHandler> = ({
  children,
  updateSidebar,
  token,
  chatId,
  isGroup,
  updateGroupMembers,
  updateUserInfo,
  updateTyping,
  userId,
  username,
  updatePendingOrders,
  updateTotalCredits,
  onOfferDeleted,
  onOfferApprove,
  onOfferChange,
  onRequestDelete,
  onMessagesUpdate,
  onMessageDeleteSucsess,
  onMessagesDelete,
  onMessageSendStart,
  userFullName,
  userAvatar,
  onMessageDelivered,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  // set socket
  useEffect(() => {
    if (token) {
      const options = {
        transports: ['websocket'],
        forceNew: true,
        reconnectionAttempts: 3,
        timeout: 2000,
        query: {
          token,
        },
      };
      setSocket(io(SOCKET_URL, options));
    }

    return () => {
      setSocket(null);
    };
  }, [token]);

  const onGroupMembersUpdate = (id: string, totalCount: number) => {
    updateGroupMembers({ id, totalParticipants: totalCount });
  };

  const onRemoveTyping = debounce((id: string) => {
    updateTyping({ id, name: null });
  }, TYPING_REMOVE_DELAY);

  const onSideBarUpdate = (messages: IChatItem[], isRoom: boolean, hasMore: boolean) => {
    updateSidebar({ chats: messages, isGroup: isRoom, hasMore });
  };

  const onTyping = throttle((id: string, name: string) => {
    updateTyping({ name, id });
    onRemoveTyping(id);
  }, TYPING_DELAY);

  const onOrderUpdate = (totalPendingOrders: number, orderId: string) => {
    updatePendingOrders({ totalPendingOrders, orderId });
  };

  const onVisionsCreditUpdate = (totalVisionsCredits: number) => {
    updateTotalCredits({ totalCredits: totalVisionsCredits });
  };

  const onVisionOfferDelete = (id: string) => {
    onOfferDeleted({ id });
  };

  const onVisionOfferApprove = (offerId: string, orderId: string, visionImage: string) => {
    onOfferApprove({ offerId, orderId, visionImage });
  };

  const onVisionOfferChange = (offer: VisionOffer) => {
    onOfferChange({ offer });
  };

  const onVisionRequestDelete = (id: string) => {
    onRequestDelete({ id });
  };

  const onChatInfoUpdate = (
    id: string,
    lastSeen: number | 'Online',
    avatar: string | null,
    shopId: string | null,
  ) => {
    updateUserInfo({ id, lastSeen, avatar, shopId });
  };

  const onMessageReceived = (message: IMessageReceived, isAdmin: boolean) => {
    onMessagesUpdate({ message, isAdmin });
  };

  const {
    addListeners,
    sendMultipleMessages,
    sendMessage,
    sendTyping,
    deleteMessage,
    removeListeners,
    removeSpecificChatListeners,
    addSpecificChatListeners,
  } = useSocket({
    onChatInfoUpdate,
    onTyping,
    onSideBarUpdate,
    onGroupMembersUpdate,
    onMessageReceived,
    onOrderUpdate,
    onVisionsCreditUpdate,
    onVisionOfferDelete,
    onVisionOfferApprove,
    onVisionOfferChange,
    onVisionRequestDelete,
  });

  // add listeners at start
  useEffect(() => {
    if (socket) {
      addListeners(socket, userId, username, userFullName);
    }

    return () => {
      removeListeners();
    };
  }, [socket, token]);

  // subscribe and unsubscribe chat
  useEffect(() => {
    if (chatId) {
      addSpecificChatListeners(chatId, isGroup);
    }

    return () => {
      if (chatId) {
        removeSpecificChatListeners(chatId);
      }
    };
  }, [chatId, socket]);

  const onMultiMessagesSend = useCallback(
    (details: SendMultipleMessages) => {
      const request = formatMultiMessagesToSend(details);

      onMessageSendStart({
        message: request,
        isMultiple: true,
        loggedInUser: { userId, username, firstName: userFullName, avatar: userAvatar },
      });
      sendMultipleMessages(request, (ids) => {
        ids.forEach((id, index) => {
          const defaultId = request.messages[index].uuid2;
          onMessageDelivered({ id, defaultId, chatId: request.to });
        });
      });
    },
    [sendMultipleMessages],
  );

  const onMessageSend = useCallback(
    (details: SendMessageRequest) => {
      const request = formatMessageToSend(details);

      const options = {
        message: request,
        isMultiple: false,
        loggedInUser: { userId, username, firstName: userFullName, avatar: userAvatar },
      };
      if (!details.shouldGetUpdateInSocket) {
        onMessageSendStart(options);
      }
      sendMessage(request, (id) => {
        if (!details.shouldGetUpdateInSocket) {
          onMessageDelivered({ id, defaultId: request.uuid, chatId: request.to });
        }
      });
    },
    [sendMessage],
  );

  const onTypingSend = useCallback(
    (id: string, isRoom: boolean) => {
      sendTyping(id, isRoom);
    },
    [sendTyping],
  );

  const onDeleteMessage = useCallback(
    (id: string, messagesIds: string[], isRoom: boolean) => {
      onMessagesDelete({ ids: messagesIds });

      const parsedMessagesIds = messagesIds.map((messageId) => messageId.split('|')).flat();

      parsedMessagesIds.forEach((messageId) => {
        deleteMessage(id, messageId, isRoom, (isSuccess) => {
          if (isSuccess) {
            onMessageDeleteSucsess({ id: messageId });
          }
        });
      });
    },
    [deleteMessage],
  );

  const value = useMemo(
    () => ({
      onMultiMessagesSend,
      onMessageSend,
      onTypingSend,
      onMessagesDelete: onDeleteMessage,
    }),
    [socket, token, onDeleteMessage],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

const mapStateToProps = (state: IStore2) => {
  const token = UserSelector.tokenSelector(state);

  const username = UserSelector.loggedUserNameSelector(state) || '';

  const userId = UserSelector.loggedUserIdSelector(state) || '';

  const userFullName = UserSelector.loggedUserFullNameSelector(state) || '';

  const userAvatar = UserSelector.avatarSelector(state);

  const chatId = state.chat.selectedChat?.id || null;

  const isGroup = state.chat.selectedChat?.isGroup || false;

  return { token, chatId, isGroup, username, userId, userFullName, userAvatar };
};

const mapDispatchToProps = {
  updatePendingOrders: UserSlicer.actions.updateTotalPendingOrders,
  updateTotalCredits: VisionsSlicer.actions.updateTotalCredits,
  updateSidebar: ChatSlicer.actions.onSideBarUpdate,
  onMessagesUpdate: ChatSlicer.actions.onMessageReceived,
  onMessageDeleteSucsess: ChatSlicer.actions.onMessageDeleteSucsess,
  onMessagesDelete: ChatSlicer.actions.onMessagesDelete,
  updateGroupMembers: ChatSlicer.actions.onGroupMembersChange,
  updateUserInfo: ChatSlicer.actions.onUserInfoChange,
  updateTyping: ChatSlicer.actions.onTyping,
  onMessageSendStart: ChatSlicer.actions.onMessageSend,
  onMessageDelivered: ChatSlicer.actions.onMessageDelivered,
  onOfferDeleted: SpecificVisionRequestSlicer.actions.onOfferDeleted,
  onOfferApprove: VisionsSlicer.actions.onOfferApprove,
  onOfferChange: SpecificVisionRequestSlicer.actions.onOfferChange,
  onRequestDelete: VisionsSlicer.actions.onRequestDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketWrapper);
