import { Steps } from 'store/onboarding/types';

export interface IOnboarding {
  step: Steps;
  birthday: number | null;
  avatar: string | null;
  mainGoal: UserProfile | null;
  interests: IInterests | null;
  channels: IOnboardingChannel[];
  shops: IOnboardingShop[];
  groups: IGroupSuggestion[];
  username: string;
  userId: string;
  shouldDisplayEmptyState: boolean;
  updateAvatar(input: { avatar: string }): void;
  updateMainGoal(input: { mainGoal: UserProfile }): void;
  onContinue(input: { userId: string }): void;
  onBack(): void;
  getInterests(): void;
  updateBirthday(input: { birthday: number }): void;
  toggleInterest(input: { interestId: string; title: string }): void;
  toggleChannel(input: { channelId: string }): void;
  toggleShop(input: { shopId: string }): void;
  toggleGroup(input: { groupId: string }): void;
  getShouldOfferSuggestions(): void;
  getGroups(): void;
  getChannels(): void;
  getShops(): void;
  onFinish(): void;
  updateUserAvatar(input: { avatar: string }): void;
  updateShowOnboarding(input: { shouldShow: boolean }): void;
  hideInterestsEmptyState(): void;
}

export interface IProfilePictureStep {
  profilePicture: string | null;
  onContinueClick(): void;
  onSkipClick(input: { userId: string }): void;
  onEditProfilePictureClick(file: File): void;
  updateUserAvatar(input: { avatar: string }): void;
}

export interface IBirthdayStep {
  birthdayValue: number | null;
  onBirthdayChange(birthday: number | null): void;
  onContinueClick(): void;
  onBackClick(): void;
}

export interface IMainGoalStep {
  onContinueClick(): void;
  onBackClick(): void;
  selectedCard: string | null;
  onCardClick(mainGoal: UserProfile): void;
}

export const enum UserProfile {
  Seller = 'Seller',
  Buyer = 'Buyer',
}

export interface IGoalCard {
  profileType: UserProfile;
  image: string;
  title: string;
  description: string;
  isSelected: boolean;
  onClick(mainGoal: UserProfile): void;
}

export interface IInterestsStep {
  interests: IInterests;
  shouldDisplayEmptyState: boolean;
  isContinueDisabled: boolean;
  onInterestToggle(input: { interestId: string; title: string }): void;
  onContinueClick(): void;
  onBackClick(): void;
  hideInterestsEmptyState(): void;
}

export interface IInterestsGroups {
  interests: IInterests;
  searchText: string;
  hasSelectedInterests: boolean;
  filteredInterests: IInterests;
  onItemClick(input: { interestId: string; title: string }): void;
}

interface IInterests {
  [key: string]: IInterestItem[];
}

export interface IInterestGroup {
  interestTitle: string;
  interests: IInterestItem[];
  onItemClick: (params: { interestId: string; title: string }) => void;
}

export interface IInterestItemButtonProps {
  interestId: string;
  title: string;
  text: string;
  isSelected: boolean;
  onItemClick: (params: { interestId: string; title: string }) => void;
}

export interface IInterestItem {
  id: string;
  text: string;
  isSelected: boolean;
}

export interface IJoinGroupsStep {
  groups: IGroupSuggestion[] | null;
  onContinueClick(): void;
  onBackClick(): void;
  onGroupsClick(input: { groupId: string }): void;
  getGroups(): void;
}

export interface IGroupSuggestion {
  id: string;
  name: string;
  description: string;
  image: string | null;
  isSelected: boolean;
}

export interface IGroupCard {
  avatar: string | null;
  groupName: string;
  description: string;
  isSelected: boolean;
  onClick(): void;
}

export interface IFollowChannelStep {
  channels: IOnboardingChannel[];
  onContinueClick(): void;
  onBackClick(): void;
  onChannelClick(input: { channelId: string }): void;
  getChannels(): void;
}

export interface IFollowShopsStep {
  shops: IOnboardingShop[] | null;
  onContinueClick(): void;
  onBackClick(): void;
  onShopClick(input: { shopId: string }): void;
  getShops(): void;
}

export interface IOnboardingShop {
  id: string;
  images: string[];
  avatar: string;
  shopName: string;
  username: string;
  userId: string;
  shopCategory: string;
  isSelected: boolean;
}

export interface IFollowChannelCard {
  channelDetails: IOnboardingChannel;
  onClick(): void;
}

export interface IFollowShopsCard {
  shopDetails: IOnboardingShop;
  onClick(): void;
}

export interface IProfileCreatedStep {
  username: string;
  profilePicture: string | null;
  onClick(): void;
}

export interface IOnboardingChannel {
  id: string;
  name: string;
  subscriberCount: number;
  description: string;
  image: string;
  isSelected: boolean;
  isPopular: boolean;
}
