import { Socket } from 'socket.io-client';
import { useCallback, useState } from 'react';

import { SocketApi } from 'store/chat/socket/SocketApi';
import { parseChatMessages, parseSocketMessage } from 'store/chat/apiParser';
import { IUseSocketCallback, MessageRequest, MessagesTypes, MultipleMessagesRequest } from 'types';

export const useSocket = (callbacks: IUseSocketCallback) => {
  let loggedInUserId: string;
  let loggedInUsername: string;
  let loggedInUserFullName: string;

  const [manager, setManager] = useState<SocketApi | null>(null);

  const addListeners = (socket: Socket, userId: string, username: string, name: string) => {
    loggedInUserId = userId;
    loggedInUsername = username;
    loggedInUserFullName = name;

    const updatedManager = new SocketApi(socket);
    setManager(updatedManager);

    updatedManager.connect(() => {});
    updatedManager.connectChat();

    updatedManager.listenToSideBarMessages((response) => {
      const chats = parseChatMessages(response.messages, loggedInUsername);

      callbacks.onSideBarUpdate(chats, response.room, response.hasMore);
    });

    updatedManager.listenToMessages((response) => {
      const sendableTypes = [
        MessagesTypes.Text,
        MessagesTypes.Image,
        MessagesTypes.Video,
        MessagesTypes.File,
      ];

      const parsedMessage = parseSocketMessage(
        response,
        loggedInUserId,
        loggedInUsername,
        loggedInUserFullName,
      );

      const isSendableMessage = sendableTypes.indexOf(parsedMessage.message.type) !== -1;

      if (response.from !== loggedInUserId || !isSendableMessage) {
        callbacks.onMessageReceived(parsedMessage.message, parsedMessage.isAdmin);
      }
    });
    updatedManager.listenToUserTyping((response) => {
      if (response.from !== loggedInUserId) {
        callbacks.onTyping(response.from, response.fromName);
      }
    });
    updatedManager.listenToGroupTyping((details) => {
      if (details.fromName !== loggedInUsername) {
        callbacks.onTyping(details.from, details.fromName);
      }
    });

    updatedManager.listenToGroupParticipants((details) => {
      callbacks.onGroupMembersUpdate(details.groupId, details.count);
    });

    updatedManager.listenToOrderUpdate((details) => {
      callbacks.onOrderUpdate(details.pendingCount, details.orderId);
    });

    updatedManager.listenToVisionsCreditChange((details) => {
      callbacks.onVisionsCreditUpdate(details.creditsLeft);
    });

    updatedManager.listenToVisionOfferChange((details) => {
      callbacks.onVisionOfferChange(details);
    });

    updatedManager.listenToVisionOfferDelete((details) => {
      callbacks.onVisionOfferDelete(details.id);
    });

    updatedManager.listenToVisionOfferApproved((details) => {
      callbacks.onVisionOfferApprove(details.offerId, details.orderId, details.visionImage);
    });

    updatedManager.listenToVisionRequestDelete((details) => {
      callbacks.onVisionRequestDelete(details.id);
    });

    updatedManager.listenToChatInfo((response) => {
      callbacks.onChatInfoUpdate(
        response.id,
        response.lastSeen,
        response.avatar || null,
        response.firstShop || null,
      );
    });
  };

  const deleteMessage = useCallback(
    (
      chatId: string,
      messageId: string,
      isGroup: boolean,
      onComplete: (success: boolean) => void,
    ) => {
      const request = { id: messageId, target: chatId, room: isGroup };

      manager?.deleteMessage(request, onComplete);
    },
    [manager],
  );

  const sendMessage = useCallback(
    (request: MessageRequest, onComplete: (id: string) => void) => {
      manager?.sendMessage(request, (response) => {
        onComplete(response.id);
      });
    },
    [manager],
  );

  const sendMultipleMessages = useCallback(
    (request: MultipleMessagesRequest, onComplete: (ids: string[]) => void) => {
      manager?.sendMultipleMessages(request, (response) => {
        onComplete(response.ids);
      });
    },
    [manager],
  );

  const sendTyping = useCallback(
    (id: string, isGroup: boolean) => {
      const request = { id, room: isGroup };
      manager?.sendTyping(request);
    },
    [manager],
  );

  const removeListeners = useCallback(() => {
    manager?.disconnect();
  }, [manager]);

  const removeSpecificChatListeners = useCallback(
    (id: string) => {
      manager?.unsubscribeSpecificChat(id);
    },
    [manager],
  );

  const addSpecificChatListeners = useCallback(
    (id: string, isRoom: boolean) => {
      manager?.subscribeSpecificChat(id, isRoom);
    },
    [manager],
  );

  return {
    sendMessage,
    addListeners,
    removeSpecificChatListeners,
    addSpecificChatListeners,
    removeListeners,
    sendMultipleMessages,
    sendTyping,
    deleteMessage,
  };
};
