import { createSlice } from '@reduxjs/toolkit';

import { IOnboardingChannel, IOnboardingState, Steps } from 'types';
import {
  getOnboardingInterests,
  getOnboardingShops,
  getHasSuggestions,
  getUserSuggestedChannels,
  getOnboardingGroups,
} from './OnboardingActions';
import {
  parsedOnboardingInterests,
  parseGroupsSuggestion,
  parseShopsSuggestion,
} from '../apiParser';
import { getDefaultAvatar } from '../user/helper';
import { parseChannelsSuggestion } from 'store/apiParser';
import { joinChatChannel } from 'store/chat/ChatActions';

const initialState: IOnboardingState = {
  step: Steps.ProfilePictureStep,
  shouldDisplayEmptyState: true,
  avatar: '',
  birthday: null,
  mainGoal: null,
  interests: null,
  channels: [],
  isChannelsLoading: false,
  shops: [],
  groups: [],
  hasSuggestions: false,
};

export const OnboardingSlicer = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    onContinue: (state, action) => {
      state.step += 1;

      if (state.step === Steps.FollowShopsStep && !state.hasSuggestions) {
        state.step = Steps.ProfileCreatedStep;
      }

      if (state.step === Steps.ProfileCreatedStep && !state.avatar) {
        const { userId } = action.payload;
        state.avatar = getDefaultAvatar(userId || '');
      }
    },
    onBack: (state) => {
      state.step -= 1;
    },
    hideInterestsEmptyState: (state) => {
      state.shouldDisplayEmptyState = false;
    },
    updateAvatar: (state, action) => {
      const { avatar } = action.payload;
      state.avatar = avatar;
    },
    updateBirthday: (state, action) => {
      const { birthday } = action.payload;
      state.birthday = birthday;
    },
    updateMainGoal: (state, action) => {
      const { mainGoal } = action.payload;
      state.mainGoal = mainGoal;
    },
    toggleInterest: (state, action) => {
      const { interestId, title } = action.payload;

      const { interests } = state;

      if (interests) {
        interests[title].forEach((item) => {
          if (item.id === interestId) {
            item.isSelected = !item.isSelected;
          }
        });
      }

      state.channels = [];
      state.shops = [];
      state.groups = [];
    },
    toggleChannel: (state, action) => {
      const { channelId } = action.payload;

      const selectedChannel = state.channels?.find(
        (channel: IOnboardingChannel) => channel.id === channelId,
      );

      if (selectedChannel) {
        selectedChannel.isSelected = !selectedChannel.isSelected;
      }
    },
    toggleShop: (state, action) => {
      const { shopId } = action.payload;

      const selectedShop = state.shops?.find((shop) => shop.id === shopId);

      if (selectedShop) {
        selectedShop.isSelected = !selectedShop.isSelected;
      }
    },
    toggleGroup: (state, action) => {
      const { groupId } = action.payload;

      const selectedGroup = state.groups?.find((group) => group.id === groupId);

      if (selectedGroup) {
        selectedGroup.isSelected = !selectedGroup.isSelected;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingInterests.fulfilled, (state, action) => {
      state.interests = parsedOnboardingInterests(action.payload);
    });
    builder.addCase(getHasSuggestions.fulfilled, (state, action) => {
      const { hasSuggestions } = action.payload;
      state.hasSuggestions = hasSuggestions;
    });
    builder.addCase(getUserSuggestedChannels.fulfilled, (state, action) => {
      state.channels = parseChannelsSuggestion(action.payload);
      state.isChannelsLoading = false;
    });
    builder.addCase(getUserSuggestedChannels.pending, (state) => {
      state.isChannelsLoading = true;
    });

    builder.addCase(getOnboardingShops.fulfilled, (state, action) => {
      state.shops = parseShopsSuggestion(action.payload);
    });

    builder.addCase(joinChatChannel.fulfilled, (state, action) => {
      const { channelId } = action.meta.arg;
      state.channels = state.channels.filter((channel) => channel.id !== channelId);
    });
    builder.addCase(getOnboardingGroups.fulfilled, (state, action) => {
      state.groups = parseGroupsSuggestion(action.payload);
    });
  },
});
