import { gql } from '@apollo/client';

export const UNEXPIRE_MESSAGE_MUTATION = gql`
  mutation unexpireMessage($id: String!) {
    unexpireMessage(id: $id) {
      success
    }
  }
`;

export const REPORT_MESSAGE_MUTATION = gql`
  mutation ReportMessage($messageId: String!, $option: String!, $extra: String) {
    reportMessage(messageId: $messageId, option: $option, extra: $extra) {
      success
    }
  }
`;

export const JOIN_CHAT_CHANNEL = gql`
  mutation JoinChatChannel($id: String!) {
    joinChatChannel(id: $id) {
      success
    }
  }
`;

export const LEAVE_CHAT_CHANNEL = gql`
  mutation LeaveChatChannel($id: String!) {
    leaveChatChannel(id: $id) {
      success
    }
  }
`;

export const GET_PRO_PATTERNS = gql`
  query GetProEarningPatterns($channelId: String!) {
    getProEarningPatterns(channelId: $channelId) {
      patterns {
        id
        image
      }
    }
  }
`;
