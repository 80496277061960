import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  IChatOption,
  IChatState,
  IGroupPrivacyOptions,
  IMessagesStore,
  MessagesStatuses,
  MessagesTypes,
  ServerStatus,
} from 'types';
import {
  onAdminToggle,
  archiveChat,
  blockUser,
  createGroup,
  editGroupDetails,
  getBothChatImages,
  getBothMessages,
  getChatFiles,
  getChatLinks,
  getChatMedia,
  getGroupInformation,
  getGroupParticipants,
  getInitialMessages,
  getNewerMessages,
  getNextChatImages,
  getOlderMessages,
  getPrevChatImages,
  getShopInformation,
  inviteUsers,
  joinGroup,
  loadReplyMessages,
  onChatSearch,
  onLeaveGroup,
  onMembersSearch,
  onMuteChat,
  onReportUser,
  onSearch,
  onUnmuteGroup,
  onUsersSearch,
  removeMemberFromGroup,
  unblockUser,
  unExpireMessage,
  getProPatterns,
  leaveChatChannel,
} from './ChatActions';
import {
  getDefaultUsersSuggestions,
  getReplyTextAndImage,
  getUpdatedGroupCount,
  getUpdatedOlderGroupCount,
  parseChatMedia,
  parsedSuggestedUsers,
  parseGroup,
  parseGroupParticipants,
  parseMessages,
  parseMessagesBatch,
  parseSearchedMessages,
  parseSendMessage,
  parseSendMessages,
  parseSendMessageToSidebar,
  parseShopInformation,
  parseSideBarChats,
  parseUserInfoLastSeen,
  selectedChatToChatItem,
  toSelectedChat,
} from './apiParser';
import { parseMessageToSidebar } from 'store/chat/apiParser';
import { translations } from 'translations/en';
import { StorageSrv } from 'services/StorageSrv';
import { onFollowUserToggle } from '../profile/ProfileActions';
import { removeSearchWordsFromMessages, updateSearchBatches } from './helper';
import { isEmptyArrayOrUndefined, uniqBy } from 'utils';
import { getDefaultAvatar } from '../user/helper';

const DEFAULT_GROUP_DETAILS = {
  serverStatus: null,
  suggestedUsers: {
    search: '',
    isLoading: false,
    users: [],
  },
  suggestedMembers: {
    search: '',
    isLoading: false,
    users: [],
    selectedUsers: [],
  },
  avatar: null,
  name: '',
  description: '',
  privacy: null,
};

const initialState: IChatState = {
  selectedChat: null,
  shops: StorageSrv.chat.getShops(),
  groups: StorageSrv.chat.getGroups(),
  searchText: '',
  isFilterActive: false,
  searchResults: {
    searchedMessages: [],
    totalSearchedMessages: 0,
    isLoading: false,
  },
  chats: null,
  archivedChats: [],
  isLoading: true,
  suggestedGroups: [],
  createChatDetails: DEFAULT_GROUP_DETAILS,
  selectedUploadOption: null,
};

export const ChatSlicer = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedChat: (state) => {
      state.selectedChat = null;
    },

    setSelectedChat: (state, action) => {
      const {
        id,
        messageDate,
        isGroup,
        avatar = null,
        name = null,
        username = null,
      } = action.payload;

      if (id !== state.selectedChat?.id) {
        const selectedChat =
          state.chats?.find((chat) => chat.id === id) ||
          state.archivedChats.find((chat) => chat.id === id) ||
          null;

        state.searchText = '';

        state.selectedChat = toSelectedChat(
          selectedChat,
          isGroup,
          avatar,
          name,
          username,
          id,
          messageDate,
        );

        if (selectedChat) {
          selectedChat.totalUnread = 0;
        }
      }
    },

    resetCreateGroupDetails: (state) => {
      state.createChatDetails = DEFAULT_GROUP_DETAILS;
    },
    resetChatMembers: (state) => {
      const { selectedChat } = state;

      if (selectedChat) {
        selectedChat.members = {
          totalCount: selectedChat.totalParticipants,
          users: [],
          hasMore: true,
          isLoading: true,
          search: '',
          skip: 0,
        };
      }
    },

    updateSelectedUploadOption: (state, action) => {
      const { type } = action.payload;
      state.selectedUploadOption = type;
    },
    onMembersSearch: (state, action) => {
      const { search } = action.payload;

      const { selectedChat } = state;

      if (selectedChat) {
        selectedChat.members.search = search;
        selectedChat.members.skip = 0;
        selectedChat.members.users = [];
        selectedChat.members.isLoading = true;
        selectedChat.members.hasMore = true;
      }
    },
    onMessagesDelete: (state, action) => {
      const { ids } = action.payload;

      const parsedIds = ids.map((id: string) => id.split('|')).flat();

      if (state.selectedChat) {
        const { mediaGallery } = state.selectedChat;
        mediaGallery.media.splice(mediaGallery.currentIndex, 1);
        mediaGallery.currentIndex -= 1;

        const { messages } = state.selectedChat;
        state.selectedChat.messages = messages.map((message) => {
          if (parsedIds.find((selectedId: string) => selectedId === message.id)) {
            return {
              ...message,
              isDeleted: true,
              type: MessagesTypes.Text,
              status: MessagesStatuses.Awaiting,
              text: translations.CHAT.DELETED_MESSAGE,
              isForwarded: false,
            };
          }

          return message;
        });
      }
    },

    onMessageDeleteSucsess: (state, action) => {
      const { id } = action.payload;

      if (state.selectedChat) {
        const { messages, id: chatId } = state.selectedChat;

        const messageIndex = messages.findIndex((currentMessage) => currentMessage.id === id);

        if (messageIndex >= 0) {
          state.selectedChat.messages[messageIndex].status = MessagesStatuses.Delivered;

          // If last image update the sidebar
          if (messageIndex === messages.length - 1) {
            const chat = state.chats?.find((currentChat) => currentChat.id === chatId);

            if (chat) {
              chat.text = translations.CHAT.DELETED_MESSAGE;
              chat.type = MessagesTypes.Text;
              chat.status = MessagesStatuses.Delivered;
            }
          }
        }
      }
    },

    onMessagesSelection: (state, action) => {
      const { selectionMode, messageId } = action.payload;

      if (state.selectedChat) {
        state.selectedChat.selectedMessages = [messageId];
        state.selectedChat.selectionMode = selectionMode;
      }
    },

    onMessagesSearch: (state, action) => {
      const { search } = action.payload;

      const { selectedChat } = state;

      if (selectedChat) {
        selectedChat.searchMessages = updateSearchBatches(search, true);
        selectedChat.messages = removeSearchWordsFromMessages(selectedChat.messages);
      }
    },
    onSearchNextClick: (state) => {
      const { selectedChat } = state;

      if (selectedChat) {
        const { messagesBatches, currentMessageBatchIndex } = selectedChat.searchMessages;

        const currentBatch = messagesBatches[currentMessageBatchIndex];

        if (currentBatch.currentIndex === currentBatch.searchMessageIndexes.length - 1) {
          const updatedBatchIndex = currentMessageBatchIndex + 1;
          selectedChat.searchMessages.currentMessageBatchIndex = updatedBatchIndex;

          const updatedBatch = messagesBatches[updatedBatchIndex];

          if (updatedBatch) {
            selectedChat.messages = updatedBatch.messages;
            selectedChat.messagesGroupCount = getUpdatedGroupCount([], updatedBatch.messages, []);
          }
        } else {
          currentBatch.currentIndex += 1;
        }
      }
    },
    onSearchPrevClick: (state) => {
      const { selectedChat } = state;

      if (selectedChat) {
        const { messagesBatches, currentMessageBatchIndex } = selectedChat.searchMessages;

        const currentBatch = messagesBatches[currentMessageBatchIndex];

        if (currentBatch.currentIndex === 0) {
          const updatedBatchIndex = currentMessageBatchIndex - 1;

          selectedChat.searchMessages.currentMessageBatchIndex = updatedBatchIndex;

          const updatedBatch = messagesBatches[updatedBatchIndex];
          selectedChat.messages = updatedBatch.messages;
          selectedChat.messagesGroupCount = getUpdatedGroupCount([], updatedBatch.messages, []);
        } else {
          currentBatch.currentIndex -= 1;
        }
      }
    },

    onSearchVisibilityToggle: (state) => {
      const { selectedChat } = state;

      if (selectedChat) {
        if (selectedChat.searchMessages.isSearchVisible) {
          selectedChat.searchMessages = updateSearchBatches('', false);
          selectedChat.messages = removeSearchWordsFromMessages(selectedChat.messages);
        } else {
          selectedChat.searchMessages.isSearchVisible = true;
        }
      }
    },
    onMessagesSelectionClose: (state) => {
      if (state.selectedChat) {
        state.selectedChat.selectedMessages = [];
        state.selectedChat.selectionMode = null;
      }
    },

    onMessageDelivered: (state, action) => {
      const { chats, selectedChat } = state;

      const { id: messageId, defaultId, chatId } = action.payload;

      if (chats && selectedChat && chatId === selectedChat.id) {
        const selectedMessage = selectedChat.messages.find((message) => message.id === defaultId);

        if (selectedMessage) {
          selectedMessage.id = messageId;
          selectedMessage.status = MessagesStatuses.Delivered;
        }
      }

      const currentChat = chats?.find((chat) => chat.id === chatId);

      if (currentChat) {
        currentChat.status = MessagesStatuses.Delivered;
      }
    },
    onMessageSend: (state, action) => {
      const { chats, archivedChats, selectedChat } = state;

      if (chats && selectedChat) {
        const { loggedInUser, message: details, isMultiple } = action.payload;

        let messages: IMessagesStore[];
        if (isMultiple) {
          messages = parseSendMessages(details, loggedInUser);
        } else {
          messages = [parseSendMessage(details, loggedInUser)];
        }

        const currentChat =
          chats.find((chat) => chat.id === details.to) ||
          archivedChats.find((chat) => chat.id === details.to) ||
          selectedChatToChatItem(selectedChat, loggedInUser);

        if (details.to === selectedChat.id) {
          const { messages: prevMessages, messagesGroupCount } = selectedChat;

          prevMessages.push(...messages);
          selectedChat.messagesGroupCount = getUpdatedGroupCount(
            prevMessages,
            messages,
            messagesGroupCount,
          );
        }

        const lastMessage = messages[messages.length - 1];

        const parsedSidebarItem = parseSendMessageToSidebar(
          currentChat,
          loggedInUser,
          lastMessage?.type,
          lastMessage?.text,
        );

        const updatedChats = chats.filter((chat) => chat.id !== currentChat?.id);

        updatedChats.unshift(parsedSidebarItem);

        state.chats = updatedChats;
      }
    },
    onMessageSelect: (state, action) => {
      const { isSelected, id } = action.payload;

      if (state.selectedChat) {
        const { selectedMessages } = state.selectedChat;

        if (isSelected) {
          state.selectedChat.selectedMessages = selectedMessages.filter(
            (messageId) => messageId !== id,
          );
        } else {
          state.selectedChat.selectedMessages.push(id);
        }
      }
    },

    onMessageReceived: (state, action) => {
      if (state.chats) {
        const { message, isAdmin } = action.payload;

        const isSelectedChat = state.selectedChat?.id === message.targetId;

        const existsChat = state.chats.find((chat) => chat.id === message.targetId);

        const parsedSidebarItem = parseMessageToSidebar(
          message,
          isSelectedChat,
          isAdmin,
          existsChat,
        );

        let updatedChats = state.chats;

        if (existsChat) {
          updatedChats = updatedChats.filter((chat) => chat.id !== message.targetId);
        }
        updatedChats.unshift(parsedSidebarItem);
        state.chats = updatedChats;

        const isMessageExists = !!state.selectedChat?.messages.find(
          (currentMessage) => currentMessage.id === message.id,
        );
        if (
          isSelectedChat &&
          state.selectedChat?.messages &&
          !state.selectedChat.hasMoreNewer &&
          !isMessageExists
        ) {
          state.selectedChat.typingName = null;

          const { messages: prevMessages, messagesGroupCount } = state.selectedChat;
          prevMessages.push(message);

          state.selectedChat.messagesGroupCount = getUpdatedGroupCount(
            prevMessages,
            [message],
            messagesGroupCount,
          );
        }
      }
    },

    onGroupMembersChange: (state, action) => {
      const { id, totalParticipants } = action.payload;

      if (state.selectedChat && id === state.selectedChat.id) {
        const { selectedChat } = state;

        if (selectedChat) {
          selectedChat.totalParticipants = totalParticipants;
          if (!selectedChat.members.search) {
            selectedChat.members.totalCount = totalParticipants;
          }
        }
      }
    },
    onTyping: (state, action) => {
      const { id, name } = action.payload;

      const { selectedChat, chats } = state;

      if (selectedChat && id === selectedChat.id) {
        selectedChat.typingName = name;
      }

      const chat = chats?.find((currentChat) => currentChat.id === id);

      if (chat) {
        chat.typingName = name;
      }
    },
    onUserInfoChange: (state, action) => {
      const { id, lastSeen, avatar, shopId } = action.payload;

      if (state.selectedChat && id === state.selectedChat.id) {
        state.selectedChat.avatar = avatar || getDefaultAvatar(id);
        state.selectedChat.shopId = shopId;
        state.selectedChat.lastSeen = parseUserInfoLastSeen(lastSeen);
      }
    },
    onReply: (state, action) => {
      const { messageId } = action.payload;

      if (state.selectedChat) {
        const selectedMessage = state.selectedChat.messages.find(
          (message) => message.id === messageId,
        );
        if (selectedMessage) {
          const name = selectedMessage.isOwner ? translations.COMMON.YOU : selectedMessage.name;

          const { text, image } = getReplyTextAndImage(selectedMessage);
          state.selectedChat.selectedReply = {
            name,
            messageId,
            text,
            image,
            userId: selectedMessage.userId,
            date: selectedMessage.date,
            type: selectedMessage.type,
            userColor: selectedMessage.userColor,
            username: selectedMessage.username,
          };
        }
      }
    },
    setDefaultCreateChatSuggestions: (state) => {
      const { chats } = state;

      const defaultUsers = getDefaultUsersSuggestions(chats);
      state.createChatDetails.suggestedMembers.users = defaultUsers;
      state.createChatDetails.suggestedUsers.users = defaultUsers;
    },
    onToggleFilter: (state) => {
      state.isFilterActive = !state.isFilterActive;
    },
    onGroupPrivacyChange: (state, action) => {
      const { privacy } = action.payload;
      state.createChatDetails.privacy = privacy;
    },

    onSuggestedUsersSearchChange: (state, action) => {
      const { search } = action.payload;
      state.createChatDetails.suggestedUsers.search = search;
      if (!search) {
        const { chats } = state;

        state.createChatDetails.suggestedUsers.users = getDefaultUsersSuggestions(chats);
      }
    },
    onSuggestedMembersSearchChange: (state, action) => {
      const { search } = action.payload;
      state.createChatDetails.suggestedMembers.search = search;
      if (!search) {
        const { chats } = state;
        state.createChatDetails.suggestedMembers.users = getDefaultUsersSuggestions(chats);
      }
    },
    onGroupAvatarChange: (state, action) => {
      const { avatar } = action.payload;
      state.createChatDetails.avatar = avatar;
    },
    setGroupDetailsToSelectedChat: (state) => {
      const { selectedChat } = state;

      if (selectedChat) {
        state.createChatDetails.avatar = selectedChat.avatar;
        state.createChatDetails.name = selectedChat.name;
        state.createChatDetails.description = selectedChat.description;
        state.createChatDetails.privacy = selectedChat.privacy || IGroupPrivacyOptions.Private;
      }
    },
    onGroupNameChange: (state, action) => {
      const { name } = action.payload;
      state.createChatDetails.name = name;
    },
    onGroupDescriptionChange: (state, action) => {
      const { description } = action.payload;
      state.createChatDetails.description = description;
    },
    onReplyClose: (state) => {
      if (state.selectedChat) {
        state.selectedChat.selectedReply = null;
      }
    },
    changeMediaIndex: (state, action) => {
      const { index } = action.payload;

      if (state.selectedChat) {
        state.selectedChat.mediaGallery.currentIndex = index;
      }
    },

    resetChatMedia: (state) => {
      if (state.selectedChat) {
        state.selectedChat.mediaGallery.media = [];
        state.selectedChat.mediaGallery.currentIndex = 0;
        state.selectedChat.mediaGallery.isLoading = false;
      }
    },
    onGroupMemberClick: (state, action) => {
      const { userId } = action.payload;

      const { selectedUsers: currentSelectedMembers, users } =
        state.createChatDetails.suggestedMembers;

      const isSelected = currentSelectedMembers.find((user) => user.id === userId);

      if (isSelected) {
        state.createChatDetails.suggestedMembers.selectedUsers = currentSelectedMembers.filter(
          (item) => item.id !== userId,
        );
      } else {
        const user = users.find((suggestedUser) => suggestedUser.id === userId);

        if (user) {
          state.createChatDetails.suggestedMembers.selectedUsers.push(user);
        }
      }
    },
    onSideBarUpdate: (state, action) => {
      const { isGroup, chats, hasMore } = action.payload;

      const regularChats = chats.filter((chat: IChatOption) => !chat.isArchived);

      const archivedChats = chats.filter((chat: IChatOption) => chat.isArchived);

      const parsedChats = parseSideBarChats(state.chats || [], regularChats, isGroup, hasMore);

      const parsedArchivedChats = parseSideBarChats(
        state.archivedChats,
        archivedChats,
        isGroup,
        hasMore,
      );

      state.isLoading = false;
      state.chats = parsedChats;
      state.archivedChats = parsedArchivedChats;
    },

    onLeaveChatChannel: (state, action) => {
      const { channelId } = action.payload;

      state.chats = state.chats?.filter((chat) => chat.id !== channelId) || [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(onSearch.pending, (state, action) => {
      const { searchText } = action.meta.arg;

      state.searchResults.isLoading = true;
      state.searchText = searchText;
    });
    builder.addCase(onSearch.fulfilled, (state, action) => {
      const { total, data } = action.payload;

      const { loggedInUserId } = action.meta.arg;

      state.searchResults.isLoading = false;
      state.searchResults.totalSearchedMessages = total;
      state.searchResults.searchedMessages = parseSearchedMessages(
        data,
        state.chats || [],
        loggedInUserId,
      );
    });
    builder.addCase(onUsersSearch.fulfilled, (state, action) => {
      state.createChatDetails.suggestedUsers.users = parsedSuggestedUsers(action.payload);
      state.createChatDetails.suggestedUsers.isLoading = false;
    });
    builder.addCase(getGroupParticipants.pending, (state) => {
      if (state.selectedChat?.members) {
        state.selectedChat.members.isLoading = true;
      }
    });
    builder.addCase(getGroupParticipants.fulfilled, (state, action) => {
      const { data, total } = action.payload;

      if (state.selectedChat?.members) {
        const { members, totalParticipants } = state.selectedChat;

        const parsedUsers = parseGroupParticipants(data);

        const updatedUsers = [...members.users, ...parsedUsers];

        members.skip = updatedUsers.length;
        if (members.search) {
          members.totalCount = total;
        } else {
          members.totalCount = totalParticipants;
        }
        members.hasMore = total - updatedUsers.length > 0;
        members.users = updatedUsers;
        members.isLoading = false;
      }
    });
    builder.addCase(onReportUser.pending, (state) => {
      if (state.selectedChat) {
        state.selectedChat.isReportLoading = true;
      }
    });

    builder.addCase(onReportUser.fulfilled, (state) => {
      if (state.selectedChat) {
        state.selectedChat.isReportLoading = false;
      }
    });

    builder.addCase(removeMemberFromGroup.pending, (state, action) => {
      const { userId } = action.meta.arg;

      if (state.selectedChat) {
        const { members } = state.selectedChat;
        members.users = members.users.filter((user) => user.id !== userId);
      }
    });
    builder.addCase(onChatSearch.pending, (state) => {
      if (state.selectedChat) {
        state.selectedChat.searchMessages.isLoading = true;
      }
    });
    builder.addCase(onChatSearch.fulfilled, (state, action) => {
      const { selectedChat } = state;

      if (selectedChat) {
        const { data, total } = action.payload;

        const { loggedInUserId, loggedInUsername, loggedInUserFullName } = action.meta.arg;

        const { searchMessages, isGroup, name, username } = selectedChat;
        searchMessages.isLoading = false;
        searchMessages.totalCount = total;
        if (!isEmptyArrayOrUndefined(data.messages)) {
          const parsedMessages = parseMessagesBatch(
            data,
            loggedInUserId,
            loggedInUsername,
            loggedInUserFullName,
            isGroup,
            name,
            username,
          );
          searchMessages.skip += parsedMessages.searchMessageIndexes.length;
          selectedChat.messages = [...parsedMessages.messages];
          selectedChat.hasMoreOlder = parsedMessages.hasPrevMore;
          selectedChat.hasMoreNewer = parsedMessages.hasNextMore;

          selectedChat.messagesGroupCount = getUpdatedOlderGroupCount(
            [],
            parsedMessages.messages,
            [],
          );

          const passedBatches =
            parsedMessages.searchMessageIndexes.length +
            searchMessages.messagesBatches.reduce(
              (acc, item) => acc + item.searchMessageIndexes.length,
              0,
            );

          searchMessages.messagesBatches = [...searchMessages.messagesBatches, parsedMessages];

          searchMessages.hasMore = passedBatches < total;
        } else {
          searchMessages.hasMore = false;
        }
      }
    });
    builder.addCase(getOlderMessages.pending, (state) => {
      if (state.selectedChat) {
        state.selectedChat.isMessagesLoading = true;
      }
    });

    builder.addCase(getOlderMessages.fulfilled, (state, action) => {
      const { data, blocked: hasMore } = action.payload;

      const { loggedInUserId, loggedInUsername, loggedInUserFullName } = action.meta.arg;

      const { selectedChat } = state;

      if (selectedChat) {
        const { messages: prevMessages, messagesGroupCount } = selectedChat;
        selectedChat.hasMoreOlder = hasMore;
        selectedChat.isMessagesLoading = false;

        const parsedMessages = parseMessages(
          data,
          loggedInUserId,
          loggedInUsername,
          loggedInUserFullName,
          selectedChat.isGroup,
          selectedChat.name,
          selectedChat.username,
        )
          .reverse()
          .filter((item) => !item.isDeleted);

        selectedChat.messagesPrevIndex = Math.max(parsedMessages.length - 1, 0);

        selectedChat.messages = [...parsedMessages, ...prevMessages];

        selectedChat.messagesGroupCount = getUpdatedOlderGroupCount(
          prevMessages,
          parsedMessages,
          messagesGroupCount,
        );
      }
    });

    builder.addCase(getNewerMessages.fulfilled, (state, action) => {
      const { data, blocked: hasMore } = action.payload;

      const { loggedInUserId, loggedInUsername, loggedInUserFullName } = action.meta.arg;

      const { selectedChat } = state;

      if (selectedChat) {
        const { messages: prevMessages, messagesGroupCount } = selectedChat;

        selectedChat.hasMoreNewer = hasMore;

        const parsedMessages = parseMessages(
          data,
          loggedInUserId,
          loggedInUsername,
          loggedInUserFullName,
          selectedChat.isGroup,
          selectedChat.name,
          selectedChat.username,
        );

        selectedChat.messagesGroupCount = getUpdatedGroupCount(
          prevMessages,
          parsedMessages,
          messagesGroupCount,
        );
        selectedChat.messages = [...prevMessages, ...parsedMessages];
      }
    });

    builder.addCase(getProPatterns.fulfilled, (state, action) => {
      const res = action.payload;

      const { selectedChat } = state;

      if (selectedChat) {
        selectedChat.patternImages = res;
      }
    });

    builder.addCase(onUsersSearch.pending, (state) => {
      state.createChatDetails.suggestedUsers.isLoading = true;
    });
    builder.addCase(onAdminToggle.pending, (state, action) => {
      const { selectedChat } = state;

      const { userId, shouldMakeAdmin } = action.meta.arg;

      if (selectedChat) {
        selectedChat.members.users = selectedChat.members.users.map((user) => {
          if (user.id === userId) {
            return { ...user, isAdmin: shouldMakeAdmin };
          }

          return user;
        });
      }
    });
    builder.addCase(onAdminToggle.rejected, (state, action) => {
      const { selectedChat } = state;

      const { userId, shouldMakeAdmin } = action.meta.arg;

      if (selectedChat) {
        selectedChat.members.users = selectedChat.members.users.map((user) => {
          if (user.id === userId) {
            return { ...user, isAdmin: !shouldMakeAdmin };
          }

          return user;
        });
      }
    });

    builder.addCase(createGroup.pending, (state) => {
      state.createChatDetails.serverStatus = ServerStatus.PENDING;
    });
    builder.addCase(createGroup.rejected, (state) => {
      state.createChatDetails.serverStatus = ServerStatus.ERROR;
    });
    builder.addCase(inviteUsers.fulfilled, (state) => {
      state.createChatDetails.serverStatus = ServerStatus.SUCCESS;
    });
    builder.addCase(onMembersSearch.pending, (state) => {
      state.createChatDetails.suggestedMembers.isLoading = true;
    });
    builder.addCase(getShopInformation.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.shops[id] = {
        details: null,
        status: ServerStatus.PENDING,
      };
    });
    builder.addCase(getShopInformation.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      state.shops[id] = {
        details: parseShopInformation(action.payload),
        status: ServerStatus.SUCCESS,
      };
      StorageSrv.chat.saveShops(state.shops);
    });
    builder.addCase(getShopInformation.rejected, (state, action) => {
      const { id } = action.meta.arg;

      state.shops[id] = {
        details: null,
        status: ServerStatus.ERROR,
      };

      StorageSrv.chat.saveShops(state.shops);
    });
    builder.addCase(getGroupInformation.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.groups[id] = {
        details: null,
        status: ServerStatus.PENDING,
      };
    });
    builder.addCase(getGroupInformation.fulfilled, (state, action) => {
      const { id, isLink } = action.meta.arg;

      const socialLinkId = isLink ? id : null;
      state.groups[id] = {
        details: parseGroup(action.payload, state.chats || [], socialLinkId),
        status: ServerStatus.SUCCESS,
      };

      StorageSrv.chat.saveGroups(state.groups);
    });
    builder.addCase(getGroupInformation.rejected, (state, action) => {
      const { id } = action.meta.arg;

      state.groups[id] = {
        details: null,
        status: ServerStatus.ERROR,
      };
    });
    builder.addCase(onMembersSearch.fulfilled, (state, action) => {
      state.createChatDetails.suggestedMembers.users = parsedSuggestedUsers(action.payload);
      state.createChatDetails.suggestedMembers.isLoading = false;
    });
    builder.addCase(getChatMedia.pending, (state) => {
      if (state.selectedChat) {
        const { media } = state.selectedChat;
        media.isLoading = true;
      }
    });
    builder.addCase(getChatMedia.fulfilled, (state, action) => {
      const { loggedInUserId, loggedInUsername } = action.meta.arg;

      const { data } = action.payload;

      const { selectedChat } = state;

      if (selectedChat) {
        const { media } = selectedChat;

        const parsedMedia = parseChatMedia(
          data,
          loggedInUsername,
          loggedInUserId,
          selectedChat.username || '',
        ).reverse();

        media.isLoading = false;

        media.hasMore = !isEmptyArrayOrUndefined(data);

        const prevList = selectedChat.media.list || [];
        media.list = [...prevList, ...parsedMedia];
      }
    });
    builder.addCase(getChatFiles.pending, (state) => {
      if (state.selectedChat) {
        const { files } = state.selectedChat;
        files.isLoading = true;
      }
    });
    builder.addCase(getChatFiles.fulfilled, (state, action) => {
      const { hasMore, files: newFiles } = action.payload;

      if (state.selectedChat) {
        const { files } = state.selectedChat;
        files.isLoading = false;

        const prevList = state.selectedChat.files.list || [];
        files.list = uniqBy([...prevList, ...newFiles], 'id');
        files.hasMore = hasMore;
      }
    });
    builder.addCase(getChatLinks.pending, (state) => {
      if (state.selectedChat) {
        const { links } = state.selectedChat;
        links.isLoading = true;
      }
    });
    builder.addCase(getChatLinks.fulfilled, (state, action) => {
      const { hasMore, links: newLinks } = action.payload;

      if (state.selectedChat) {
        const { links } = state.selectedChat;
        links.isLoading = false;

        const prevList = state.selectedChat.links.list || [];
        links.list = [...prevList, ...newLinks];
        links.hasMore = hasMore;
      }
    });
    builder.addCase(getBothChatImages.fulfilled, (state, action) => {
      const { date, loggedInUserId, loggedInUsername } = action.meta.arg;

      const { data } = action.payload;

      const { selectedChat } = state;

      if (selectedChat) {
        const parsedMedia = parseChatMedia(
          data,
          loggedInUsername,
          loggedInUserId,
          selectedChat.username || '',
        );

        const { mediaGallery } = selectedChat;
        mediaGallery.media = parsedMedia;
        mediaGallery.currentIndex = mediaGallery.media.findIndex((image) => image.date === date);
      }
    });
    builder.addCase(getPrevChatImages.fulfilled, (state, action) => {
      const { selectedChat } = state;

      const { loggedInUserId, loggedInUsername } = action.meta.arg;

      if (selectedChat) {
        const parsedMedia = parseChatMedia(
          action.payload.data,
          loggedInUsername,
          loggedInUserId,
          selectedChat.username || '',
        );

        const prevList = [...selectedChat.mediaGallery.media];
        selectedChat.mediaGallery.currentIndex += parsedMedia.length;
        selectedChat.mediaGallery.media = [...parsedMedia, ...prevList];
      }
    });
    builder.addCase(getNextChatImages.fulfilled, (state, action) => {
      const { selectedChat } = state;

      if (selectedChat) {
        const { loggedInUserId, loggedInUsername } = action.meta.arg;

        const parsedMedia = parseChatMedia(
          action.payload.data,
          loggedInUsername,
          loggedInUserId,
          selectedChat.username || '',
        );

        const prevList = [...selectedChat.mediaGallery.media];
        selectedChat.mediaGallery.media = [...prevList, ...parsedMedia];
      }
    });
    builder.addCase(editGroupDetails.fulfilled, (state) => {
      const { name, avatar, description, privacy } = state.createChatDetails;

      const { selectedChat, groups, chats } = state;

      if (selectedChat && chats) {
        selectedChat.avatar = avatar;
        selectedChat.privacy = privacy;
        selectedChat.name = name;
        selectedChat.description = description;

        const chat = chats.find((currentChat) => currentChat.id === selectedChat.id);

        const groupDetails = groups[selectedChat.id]?.details;

        if (chat) {
          chat.avatar = avatar;
          chat.name = name;
          chat.privacy = privacy;
          chat.description = description;
        }
        if (groupDetails) {
          groupDetails.avatar = avatar;
          groupDetails.description = description;
          groupDetails.name = name;
          StorageSrv.chat.saveGroups(state.groups);
        }
      }
      state.createChatDetails.serverStatus = ServerStatus.SUCCESS;
    });
    builder.addCase(editGroupDetails.pending, (state) => {
      state.createChatDetails.serverStatus = ServerStatus.PENDING;
    });
    builder.addCase(getInitialMessages.pending, (state) => {
      if (state.selectedChat) {
        state.selectedChat.isMessagesLoading = true;
        state.selectedChat.hasMoreNewer = false;
      }
    });
    builder.addCase(getInitialMessages.fulfilled, (state, action) => {
      if (state.selectedChat) {
        state.selectedChat.messagesPrevIndex = action.payload.data.length - 1;
      }
    });
    builder.addCase(loadReplyMessages.fulfilled, (state, action) => {
      const { data, blocked: hasMore } = action.payload;

      const { selectedChat } = state;

      const { replyDate, loggedInUserId, loggedInUsername, loggedInUserFullName } = action.meta.arg;

      if (selectedChat) {
        selectedChat.hasMoreOlder = hasMore;
        selectedChat.hasMoreNewer = true;

        const parsedMessages = parseMessages(
          data,
          loggedInUserId,
          loggedInUsername,
          loggedInUserFullName,
          selectedChat.isGroup,
          selectedChat.name,
          selectedChat.username,
        );
        selectedChat.messages = parsedMessages;
        selectedChat.selectedMessageDate = replyDate;

        selectedChat.messagesGroupCount = getUpdatedOlderGroupCount([], parsedMessages, []);
      }
    });
    builder.addCase(archiveChat.fulfilled, (state, action) => {
      const updatedIsArchived = action.meta.arg.isArchived;

      if (state.selectedChat && state.chats) {
        state.selectedChat.isArchived = updatedIsArchived;
        if (updatedIsArchived) {
          const chatToArchive = state.chats?.find((chat) => chat.id === state.selectedChat?.id);

          if (chatToArchive) {
            chatToArchive.isArchived = true;
            state.archivedChats.push(chatToArchive);
            state.chats = state.chats.filter((chat) => chat.id !== chatToArchive.id);
          }
        } else {
          const chatToUnarchivedIndex = state.archivedChats?.findIndex(
            (chat) => chat.id === state.selectedChat?.id,
          );
          if (chatToUnarchivedIndex !== -1) {
            state.chats[chatToUnarchivedIndex].isArchived = false;
            state.chats.push(state.archivedChats[chatToUnarchivedIndex]);
            state.chats.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
            state.archivedChats.splice(chatToUnarchivedIndex, 1);
          }
        }
      }
      if (updatedIsArchived) {
        state.selectedChat = null;
      }
    });
    builder.addCase(onLeaveGroup.fulfilled, (state) => {
      const { selectedChat, chats, groups } = state;

      if (selectedChat && chats) {
        const groupDetails = groups[selectedChat.id]?.details;

        if (groupDetails) {
          if (groupDetails.totalParticipants === 1) {
            groups[selectedChat.id].status = ServerStatus.ERROR;
            groups[selectedChat.id].details = null;
          } else {
            groupDetails.isMember = false;
          }
          StorageSrv.chat.saveGroups(state.groups);
        }

        const isArchivedChat = !!state.archivedChats.find(
          (chat) => chat.id === state.selectedChat?.id,
        );
        if (isArchivedChat) {
          state.archivedChats = state.archivedChats.filter(
            (chat) => chat.id !== state.selectedChat?.id,
          );
        } else {
          state.chats = chats.filter((chat) => chat.id !== state.selectedChat?.id);
        }
      }
      state.selectedChat = null;
    });
    builder.addCase(joinGroup.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      const { groupId } = action.payload;

      const { groups } = state;

      const details = groups[id]?.details;

      if (details) {
        details.isMember = true;
        StorageSrv.chat.saveGroups(state.groups);
      }

      const chat = state.chats?.find((currentChat) => currentChat.id === groupId);

      if (chat) {
        state.selectedChat = toSelectedChat(chat, true, null, null, null, groupId);
      }
    });
    builder.addCase(unExpireMessage.fulfilled, (state, action) => {
      const { messageId } = action.meta.arg;

      const selectedMessage = state.selectedChat?.messages.find(
        (message) => message.id === messageId,
      );

      if (selectedMessage) {
        selectedMessage.expiryText = null;
      }
    });

    builder.addCase(leaveChatChannel.fulfilled, (state, action) => {
      const selectedChannelId = action.meta.arg.channelId;
      state.selectedChat = null;
      state.chats = state.chats?.filter((chat) => chat.id !== selectedChannelId) || [];
    });

    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state, action) => {
        const { userId, isFollowing } = action.meta.arg;

        if (state.selectedChat) {
          const user = state.selectedChat.members.users.find(
            (currentUser) => currentUser.id === userId,
          );
          if (user) {
            user.isFollowing = !isFollowing;
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        onMuteChat.pending,
        onUnmuteGroup.pending,
        onMuteChat.rejected,
        onUnmuteGroup.rejected,
      ),
      (state) => {
        if (state.selectedChat && state.chats) {
          state.selectedChat.isMuted = !state.selectedChat.isMuted;

          const chatToUpdate = state.chats.find((chat) => chat.id === state.selectedChat?.id);

          if (chatToUpdate) {
            chatToUpdate.isMuted = !chatToUpdate.isMuted;
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(getNextChatImages.pending, getPrevChatImages.pending, getBothChatImages.pending),
      (state) => {
        if (state.selectedChat) {
          state.selectedChat.mediaGallery.isLoading = true;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(blockUser.pending, unblockUser.pending, blockUser.rejected, unblockUser.rejected),
      (state) => {
        const { selectedChat } = state;

        if (selectedChat) {
          selectedChat.isBlocked = !selectedChat.isBlocked;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        getNextChatImages.fulfilled,
        getPrevChatImages.fulfilled,
        getBothChatImages.fulfilled,
        getNextChatImages.rejected,
        getPrevChatImages.rejected,
        getBothChatImages.rejected,
      ),
      (state) => {
        if (state.selectedChat) {
          state.selectedChat.mediaGallery.isLoading = false;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(getBothMessages.fulfilled, getInitialMessages.fulfilled),
      (state, action) => {
        const { data, blocked: hasMore } = action.payload;

        const { selectedChat } = state;

        const { loggedInUserId, loggedInUsername, loggedInUserFullName } = action.meta.arg;

        if (selectedChat) {
          selectedChat.hasMoreOlder = hasMore;
          selectedChat.isMessagesLoading = false;

          const parsedMessages = parseMessages(
            data,
            loggedInUserId,
            loggedInUsername,
            loggedInUserFullName,
            selectedChat.isGroup,
            selectedChat.name,
            selectedChat.username,
          ).reverse();
          selectedChat.messages = parsedMessages;
          selectedChat.messagesGroupCount = getUpdatedOlderGroupCount([], parsedMessages, []);
        }
      },
    );
  },
});
