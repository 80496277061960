import {
  IGroupSuggestion,
  IInterestItem,
  IOnboardingChannel,
  IOnboardingShop,
  UserProfile,
} from 'pages/onboarding/types';

export interface IOnboardingState {
  step: Steps;
  shouldDisplayEmptyState: boolean;
  birthday: number | null;
  avatar: string | null;
  mainGoal: UserProfile | null;
  interests: IInterests | null;
  channels: IOnboardingChannel[];
  isChannelsLoading: boolean;
  shops: IOnboardingShop[];
  groups: IGroupSuggestion[];
  hasSuggestions: boolean;
}

export interface IInterests {
  [category: string]: IInterestItem[];
}

export enum Steps {
  ProfilePictureStep,
  BirthdayStep,
  MainGoalStep,
  InterestsStep,
  ChannelsStep,
  FollowShopsStep,
  JoinGroupsStep,
  ProfileCreatedStep,
}

export interface IOnboardingApi {
  getOnboardingInterests(token: string): Promise<IGetInterestSectionResponse>;
  getHasSuggestions(token: string): Promise<IGetHasSuggestionsResponse>;

  getShopSuggestions(
    interests: string[],
    birthDate: number,
    token: string,
  ): Promise<IGetShopsResponse>;

  getGroupSuggestions(
    interests: string[],
    birthDate: number | null,
    token: string,
  ): Promise<IGroupSuggestionResponse>;

  updateOnboardingData(
    interests: string[],
    channels: string[],
    businesses: string[],
    groups: string[],
    token: string,
  ): Promise<void>;
}

export interface IGroupSuggestionResponse {
  suggestions: IGroupResponse[];
}

export interface IGroupResponse {
  id: string;
  name: string;
  participants: number;
  description: string;
  image: string | null;
  isSelected: boolean;
}

export interface IGetInterestSectionResponse {
  sections: IInterestSectionResponse[];
}

export interface IGetHasSuggestionsResponse {
  hasSuggestions: boolean;
}

export interface IInterestSectionResponse {
  title: string;
  items: IInterestItem[];
}

export interface IGetShopsResponse {
  suggestions: IShopResponse[];
}

export interface IShopResponse {
  id: string;
  name: string;
  rating: number;
  totalReviews: number;
  categories: string[];
  description: string;
  image: string | null;
  userId: string;
  avatar: string;
  images: string[];
  username: string;
}
