import { ProEarningPatternItem } from 'graphql/generated/graphql';
import {
  ChatInformationSidebarOptions,
  IChatMedia,
  IFeedStore,
  IForwardMessageOption,
  IMessageCount,
  IMessageItemDetails,
  ISelectedReply,
  MessageActionOptions,
  MessageIds,
  MessageSelectionOptions,
  popupIdsOptions,
} from 'types';

export * from './message/types';

export interface IBlockedUserBar {
  name: string;
  onClick(): void;
}

export enum MessageTabOptions {
  Messages = '0',
  Feed = '1',
}

export interface IChatMessagesHeader {
  title: string;
  avatar: string | null;
  description: string;
  isGroup: boolean;
  isArchived: boolean;
  isSuperAdmin: boolean;
  isMuted: boolean;
  search: string;
  isSearchVisible: boolean;
  currentSearchIndex: number;
  totalSearches: number;
  selectedTab: MessageTabOptions;
  isChannel: boolean;
  onBack(): void;
  onDetailsClick(): void;
  onSearchNextClick(): void;
  onSearchPrevClick(): void;
  onSearchClose(): void;
  onMessagesSearch(options: { search: string }): void;
  onTabChange(tab: MessageTabOptions): void;
  onOptionsClick(option: ChatMenuOptions): void;
}

export interface IImagePreviewPopup {
  selectedImage: IChatMedia;
  totalImages: number;
  currentIndex: number;
  isLoading: boolean;
  loggedInUserId: string;
  loggedInUsername: string;
  shouldShowDeleteButton: boolean;
  loadPrevImages(options: { loggedInUserId: string; loggedInUsername: string }): void;
  loadNextImages(options: { loggedInUserId: string; loggedInUsername: string }): void;
  updateMediaIndex(input: { index: number }): void;
  onHide(): void;
  showPopup(input: { id: string; messagesId: string[] }): void;
}

export enum ChatMenuOptions {
  Search = 'search',
  Mute = 'mute',
  Unmute = 'unmute',
  Leave = 'leave',
  Archive = 'archive',
  Unarchive = 'unarchive',
  LeaveChannel = 'leaveChannel',
}

export enum MediaOption {
  Photo = 'PHOTO',
  File = 'FILE',
}

export interface ILinkDetails {
  title: string;
  image: string;
  link: string;
  description: string;
  isVisible: boolean;
}

export interface ILinkDetailsResponse {
  title: string;
  imageUrl: string;
  description: string;
}

export interface IChatInput {
  text: string;
  isGroup: boolean;
  showPopup(input: { id: string; selectedUploadOption: MediaOption | null }): void;
  onChange(text: string): void;
  onMediaClick(type: MediaOption): void;
  onSend(): void;
}

export interface IMessagesWrapper {
  isArchived: boolean;
  isMuted: boolean;
  isGroup: boolean;
  chatId: string;
  isSuperAdmin: boolean;
  messages: IMessageItemDetails[];
  selectedUploadOption: MediaOption | null;
  avatar: string | null;
  searchIndex: number | null;
  name: string;
  isSearchVisible: boolean;
  selectionMode: MessageSelectionOptions | null;
  username: string | null;
  selectedChatId: string;
  loggedInUserFullName: string;
  loggedInUsername: string;
  loggedInUserId: string;
  groupsCount: IMessageCount[];
  search: string;

  getSearchMessages(options: {
    loggedInUserId: string;
    loggedInUserFullName: string;
    loggedInUsername: string;
  }): void;
  showPopup(input: { id: string; messageId?: string }): void;
  showToast(input: { link: string; id: MessageIds }): void;
  onChatUnselect(): void;
  onDetailsClick(option: ChatInformationSidebarOptions): void;
  onMessagesSelectionSubmit(): void;
  onMessagesSelection(options: { selectionMode: MessageSelectionOptions; messageId: string }): void;
  onTyping(): void;
  onSearchOpen(): void;
  onUnmute(): void;
  onArchiveChat(input: { isArchived: boolean }): void;
  loadOlderMessages(): void;
  loadInitialMessages(): void;
  loadNewerMessages(): void;
  onReplyMessage(options: { messageId: string }): void;
  setSelectedChat(options: {
    id: string;
    isGroup: boolean;
    avatar: string;
    name: string;
    username: string;
  }): void;
  onMediaSend(media: ISendMedia[]): void;
  onFilesSend(files: ISendFile[]): void;
  onTextSend(text: string, linkDetails: ILinkDetails | null): void;
  loadReplyMessages(replyDate: number): void;
  updateSelectedUploadOption(input: { type: MediaOption | null }): void;
  openModal(): void;
}

export interface IMessagesFooter {
  reply: ISelectedReply | null;
  defaultText: string;
  isGroup: boolean;
  isLinksPopupVisible: boolean;
  isChatBlocked: boolean;
  totalSelectedMessages: number;
  selectionMode: MessageSelectionOptions | null;
  name: string;
  token: string;
  isChannel: boolean;
  showPopup(input: { id: string; selectedUploadOption: MediaOption | null }): void;
  onSend(text: string, linkDetails: ILinkDetails | null): void;
  onUnblock(): void;
  onSelectionClose(): void;
  shopPopup(option: { id: popupIdsOptions }): void;
  onSelectionSubmit(): void;
  onTyping(): void;
  onMediaClick(type: MediaOption): void;
  onReplyClose(): void;
}

export interface ISendMediaPreview {
  onClose(): void;
  onSubmit(media: ISendMedia[]): void;
}

export interface ISendMedia {
  src: string;
  text: string;
}

export interface ISendFilePreview {
  onClose(): void;
  onSubmit(files: ISendFile[]): void;
}

export interface ISendFile {
  src: string;
  name: string;
  size: string;
  text: string;
}

export interface ISelectMediaPreview {
  type: MediaOption;
  onClose(): void;
  onFileSubmit(file: ISendFile[]): void;
  onImageSubmit(media: ISendMedia[]): void;
}

export interface IMessagesList {
  hasMoreNewerMessages: boolean;
  hasMoreOlderMessages: boolean;
  searchIndex: number | null;
  initialMessageIndex: number;
  isGroup: boolean;
  groupsCount: IMessageCount[];
  messages: IMessageItemDetails[];
  selectionMode: MessageSelectionOptions | null;
  replyIndex: number | null;
  messagesPrevIndex: number;
  selectedMessageDate: number | null;
  isLoading: boolean;
  selectedChannelId: string | null;
  isPremium: boolean;
  showPopup(input: { id: string; mediaItem?: IChatMedia; offerId?: string }): void;
  onFileDownload(input: { messageId: string }): void;
  loadOlderMessages(): void;
  onSelectToggle(options: { id: string; isSelected: boolean }): void;
  loadNewerMessages(): void;
  onMessageActionClick(messageId: string, text: string, action: MessageActionOptions): void;
  onGroupView(options: { id: string; isGroup: boolean }): void;
  onGroupJoin(options: { id: string; isLink: boolean; shouldJoinGroup: boolean }): void;
  loadNewerMessages(): void;
  loadInitialMessages(): void;
  onReplyClick(id: string, date: number): void;
  channelUnsubscribe(): void;
  leaveChannel(input: { channelId: string }): void;
  patternImages: ProEarningPatternItem[];
  isChannel: boolean;
}

export interface IMessageSystem {
  text: string;
  className?: string;
}

export interface ISelectBar {
  totalSelected: number;
  type: MessageSelectionOptions;
  onClose(): void;
  onSubmit(): void;
}

export interface ILinkPreview {
  title: string;
  image: string;
  description: string;
  onClose(): void;
}

export interface IForwardChatOption {
  avatar: string | null;
  name: string;
  username: string | null;
  id: string;
  isGroup: boolean;
}

export interface IForwardMessagesPopup {
  onClose(): void;
  onSubmit(): void;
  chats: IForwardChatOption[];
  selectedMessages: IForwardMessageOption[];
}

export interface ISearchHeader {
  search: string;
  currentSearchIndex: number;
  totalSearches: number;
  onSearch(text: string): void;
  onClose(): void;
  onPrevClick(): void;
  onNextClick(): void;
}

export interface IChatFeedHeader {
  avatar: string | null;
  name: string;
  totalMembers: number;
  onShareLinkClick(): void;
  onMembersClick(): void;
}

export interface IChatFeed {
  isCreatePostLoading: boolean;
  selectedChatId: string | null;
  name: string;
  avatar: string | null;
  totalMembers: number;
  token: string | null;
  postsDetails: IFeedStore;
  onShareLinkClick(): void;
  onMembersClick(): void;
  getPosts(input: { chatId: string; token: string }): void;
}
