import {
  FeedTypes,
  IChatGroupsState,
  IChatOption,
  IMessageItemDetails,
  ISelectedChat,
  MediaOption,
  MessageSelectionOptions,
  MessagesTypes,
} from 'types';

export * from './sideBar/types';

export * from './messages/types';

export * from './popups/types';

export * from './chatInfoSidebar/types';

export interface ISideBarWrapper {
  totalArchivedChats: number;
  onCreateGroupReset(): void;
  setCreateChatDefaultValues(): void;
}

export interface IReplyBar {
  type: MessagesTypes;
  name: string;
  username: string;
  text: string | null;
  image: string | null;
  userColor: string;
  onClose(): void;
}

export interface IChat {
  loggedInUserId: string;
  loggedInUsername: string;
  loggedInUserFullName: string;
  selectedChat: ISelectedChat | null;
  selectedMessages: string[];
  selectionMode: MessageSelectionOptions | null;
  selectedUploadOption: MediaOption | null;
  isSidebarLoaded: boolean;
  groups: IChatGroupsState;
  chats: IChatOption[] | null;
  messages: IMessageItemDetails[];
  updateSelectedFeed(input: { type: FeedTypes }): void;
  setSelectedChat(input: {
    id: string;
    isGroup: boolean;
    username: string | null;
    avatar: string | null;
    name: string | null;
  }): void;
  resetSelectedChat(): void;
  loadOlderMessages(input: {
    loggedInUserId: string;
    loggedInUsername: string;
    loggedInUserFullName: string;
  }): void;
  loadNewerMessages(input: {
    loggedInUserId: string;
    loggedInUsername: string;
    loggedInUserFullName: string;
  }): void;
  loadBothMessages(input: {
    loggedInUserId: string;
    loggedInUsername: string;
    loggedInUserFullName: string;
  }): void;
  loadInitialMessages(input: {
    loggedInUserId: string;
    loggedInUsername: string;
    loggedInUserFullName: string;
  }): void;
  loadReplyMessages(input: {
    replyDate: number;
    loggedInUserId: string;
    loggedInUserFullName: string;
    loggedInUsername: string;
  }): void;
  resetFeed(): void;
  getGroupInfo(input: { id: string; isLink: boolean; shouldNavigateToChat: boolean }): void;
  onGroupJoin(input: { id: string; isLink: boolean }): void;
  showPopup(options: any): void;
  onReplyClose(): void;
  updateSelectedUploadOption(input: { type: MediaOption | null }): void;
  leaveChannel(input: { channelId: string }): void;
}

export enum ChatErrorMessageTypes {
  GroupInvite,
  LeaveGroup,
}
